import Vue from 'vue';
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import { TOAST, TYPE_ACTION_MEDIA_MANAGER } from '@/constants';
import menuManagerGalleryUtil from '@/utils/menuManagerGallery.js';
import STORE from '@/store/types';

export default Vue.extend({
  name: 'MediaManager',

  props: {
    statusShowManager: {
      type: Object,
      required: true
    },
    deleteId: {
      type: String,
      required: true
    },
    typeDelete: {
      type: String,
      required: true
    },
    urlImage: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    headquater: {}
  }),

  created() {
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
  },

  methods: {
    async actionDelete() {
      switch (this.typeDelete) {
        case TYPE_ACTION_MEDIA_MANAGER.DELETE_IMAGE:
          await this.deletePanoramic();
          break;

        case TYPE_ACTION_MEDIA_MANAGER.DELETE_ALBUM:
          await this.deleteAlbum();
          break;

        default:
          break;
      }
    },

    async deletePanoramic() {
      menuManagerGalleryUtil(this.deleteId);
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, true);
      const { result } = await surePromise(requests.deletePanoramic(this.deleteId));
      await this.validateDeletePanoramic(result.ok);
    },

    async validateDeletePanoramic(result) {
      if (result) {
        await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.headquater.id);
        await this.controllersUtilsInterface(TOAST.SUCCESS, 'La imagen se borro exitosamente');
      } else {
        await this.controllersUtilsInterface(TOAST.ERROR, 'La imagen se borro exitosamente');
      }
    },

    async deleteAlbum() {
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, true);
      menuManagerGalleryUtil(this.deleteId);
      const result = await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DELETE_ALBUM, this.deleteId, this.headquater.id);
      if (result) {
        await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.headquater.id);
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
        await this.controllersUtilsInterface(TOAST.SUCCESS, 'El album se borro exitosamente');
      } else {
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
        await this.controllersUtilsInterface(TOAST.ERROR, 'Error al intentar borrar el album');
      }
    },

    async managerModalUpdateFile() {
      const fileUpdate = {
        id: this.deleteId,
        urlImage: this.urlImage,
        title: this.title,
        description: this.description,
        typeUpdate: this.typeDelete
      };
      await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_FILE_UPDATE, fileUpdate);
      await this.$store.dispatch(STORE.ACTIONS.ALBUMS.SHOW_FILE_UPDATE, true);
      menuManagerGalleryUtil(this.deleteId);
    },

    async controllersUtilsInterface(status, message) {
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, { show: true, status, message });
    }
  }
});
